import React from 'react';
import * as PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './content-column.module.scss';
import Col from 'react-bootstrap/Col';

const ContentColumn = ({
  children,
  centered = false,
  gray = false,
  spacingY = false,
  colClassName = false,
  className = false,
  ...colProps
}) => {
  const rootClasses = classnames(className, styles.root, {
    [styles.centered]: centered,
    [styles.spacingY]: spacingY,
  });

  const colClasses = classnames(colClassName, {
    [styles.gray]: gray,
  });

  return (
    <Col {...colProps} className={colClasses}>
      <div className={rootClasses}>{children}</div>
    </Col>
  );
};

ContentColumn.propTypes = {
  centered: PropTypes.bool,
  gray: PropTypes.bool,
  spacingY: PropTypes.bool,
  ...Col.propTypes,
};

const LargeH2 = ({ children }) => (
  <h2 className={styles.largeH2}>{children}</h2>
);

ContentColumn.LargeH2 = LargeH2;

export default ContentColumn;
