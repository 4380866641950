import React from 'react';
import classnames from 'classnames';

import styles from './page-wrapper.module.scss';

const PageWrapper = ({ children, padding = false, hero = true }) => {
  const rootClasses = classnames({
    [styles.hasPadding]: padding,
    [styles.noHero]: !hero,
  });

  return <div className={rootClasses}>{children}</div>;
};

export default PageWrapper;
